<template>
  <v-card>
    <v-card-text>
      <v-row no-gutters>
        <v-col cols="4">
          <v-avatar size="74" tile>
            <v-img
              :src="require('@/assets/Placeholder-Form@2x.svg')"
            ></v-img> </v-avatar
        ></v-col>
        <v-col cols="8">
          <v-chip
            v-if="mode === 'waiting'"
            class="warning lighten-1 text-title-5 warning--text text--darken-2"
            >รอการส่ง</v-chip
          >
          <v-chip
            v-if="mode === 'savedraft'"
            class="primary lighten-1 text-title-5 primary--text text--darken-1"
            >บันทึกแบบร่าง</v-chip
          >
          <v-chip
            v-if="mode === 'done'"
            class="success lighten-1 text-title-5 success--text text--darken-2"
            >ส่งแล้ว</v-chip
          >
           <div class="text-body-normal primary--text text--darken-4">
           {{formTypeName}}
          </div>
          <div class="text-body-large primary--text text--darken-4">
           {{name}}
          </div>
          <div class="text-body-normal natural--text text--darken-2">
              CID 
            <TheMask mask="#-####-#####-##-#" :value="cid"/>
          </div>
          <div class="text-body-normal natural--text text--darken-2">
            <v-icon color="natural darken-2">mdi-calendar-clock</v-icon>
           {{ $dayjs(new Date(timestamp) ).locale('th').format('DD MMMM YYYY HH:mm')  }}
          
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions v-if="mode === 'savedraft'">
      <v-btn color="primary" outlined block @click="$emit('edit')"
        ><v-icon>mdi-file-edit-outline</v-icon>แก้ไข</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import {TheMask} from 'vue-the-mask'

export default {
  components: {TheMask},
  props: {
    name: {
      type: String,
      default: "นวลจันทร์ อภิจรรยาธรรม",
    },
    formTypeName: {
      type: String,
      default: "นวลจันทร์ อภิจรรยาธรรม",
    },
    cid: {
      type: String,
      default: "",
    },
    timestamp: {
      type: String,
      default: "",
    },
    mode: {
      type: String,
      default: "waiting",
    },
  },
};
</script>

<style lang="scss" scoped>
.v-sheet.v-card {
  border-radius: 6px;
}
.v-btn {
  border-radius: 6px;
}
</style>